import { Injectable, inject } from '@angular/core';
import {
  ActivatedRoute,
  ActivationEnd,
  NavigationEnd,
  Router,
} from '@angular/router';

import {
  Observable,
  distinctUntilChanged,
  filter,
  map,
  of,
  shareReplay,
  startWith,
  tap,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouteReaderService {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  public route$(paramName: string) {
    return this.routeSnapshot$(paramName);
  }
  public routeEnhanced$(paramName: string) {
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      startWith(this.router.events),
      map((e) => {
        const child: ActivatedRoute = this.findChild();
        const newValue = child.snapshot.params[paramName];
        return newValue;
      }),
      distinctUntilChanged((prev, current) => {
        return prev === current;
      }),
      shareReplay(1)
    );
  }
  public routeEvent$(paramName: string) {
    return this.router.events.pipe(
      filter(
        (val) =>
          val instanceof ActivationEnd && val?.snapshot?.params?.[paramName]
      ),
      map((val) => (val as ActivationEnd).snapshot.params[paramName]),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }
  public firstChildSnapshot() {
    let child = this.activatedRoute.snapshot;
    while (child.firstChild) {
      child = child.firstChild;
    }
    return child;
  }
  public findChild() {
    let child = this.activatedRoute;
    while (child.firstChild) {
      child = child.firstChild;
    }
    return child;
  }
  public routeSnapshot$(paramName: string) {
    const child = this.findChild();
    return child.params.pipe(
      filter((params) => params[paramName] !== undefined),
      map((params) => params[paramName] as string),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }
}

export function firstChildSnapshot() {
  const reader = inject(RouteReaderService);
  return reader.firstChildSnapshot();
}
export function route$(paramName: string): Observable<string> {
  const reader = inject(RouteReaderService);
  return reader.route$(paramName) as Observable<string>;
}

export function routeEnhanced$(paramName: string): Observable<string> {
  const reader = inject(RouteReaderService);
  return reader.routeEnhanced$(paramName) as Observable<string>;
}
