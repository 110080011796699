import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { StartupCoreService } from './startup-core.service';
import { InvestorHubService } from '../core/investor-hub.service';
import { StartupDataInput } from '@codenteam/portal/graphql';
import { HttpErrorResponse } from '@angular/common/http';
import { PopupModelComponent } from '../wizard/popup-model/popup-model.component';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EmployeesNumber } from '@codenteam/portal/graphql';
import { Title } from '@angular/platform-browser';
import { ErrorDialogComponent } from '../scans/error-dialog/error-dialog.component';
import { StartupApplyFormValidationDto } from './startup-apply-form.dto';
import { validateSync } from 'class-validator';

@Component({
  selector: 'codenteam-startup-apply',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatTooltip,
    MatIcon,
    MatProgressSpinnerModule,
  ],
  templateUrl: './startup-apply.component.html',
})
export class StartupApplyComponent implements OnInit {
  accountUuid: string;
  slug: string;
  token: string;
  instantInvite: boolean;
  startupUuid: string;
  userId: number;
  isInValid: boolean;
  applied: boolean;
  isSubmitting: boolean;
  bigFiles: File[] = [];

  startupDataForm = new FormGroup({
    companyName: new FormControl<string>(null, [
      Validators.required,
      this.noEmptyAfterTrimValidator(),
    ]),
    companyEmail: new FormControl<string>(null, [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/),
    ]),
    companyWebsite: new FormControl<string>(null, [
      Validators.required,
      Validators.pattern(
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/
      ),
    ]),
    linkedInUrl: new FormControl<string>(null, [
      Validators.required,
      this.linkedinUrlValidator(),
    ]),
    companyDescription: new FormControl<string>(null, [
      Validators.required,
      this.noEmptyAfterTrimValidator(),
    ]),
    contactName: new FormControl<string>(null, [
      Validators.required,
      this.noEmptyAfterTrimValidator(),
    ]),
    contactEmail: new FormControl<string>(null, [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/),
    ]),
    pentestScanUrl: new FormControl<string>(null, [
      Validators.required,
      Validators.pattern(
        /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/
      ),
    ]),
    numberOfEmployees: new FormControl<string>(null, [Validators.required]),
    logo: new FormControl<string | ArrayBuffer>(null, []),
    filesNames: new FormArray([]),
  });
  logoPreview: string | ArrayBuffer = null;

  files: File[] = [];

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('filesUpload') filesUpload: ElementRef;

  constructor(
    private activated: ActivatedRoute,
    private changeDetectorRefs: ChangeDetectorRef,
    private startupCoreService: StartupCoreService,
    private investorHubService: InvestorHubService,
    private dialogRef: MatDialog,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.setTitle();
    this.token = this.activated.snapshot.queryParamMap.get('token');
    if (!this.token || this.token.length === 0) {
      this.openInvalidUrlDialog();
    }
    this.checkLinkValidity();
  }

  noEmptyAfterTrimValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = control.value && control.value.trim().length > 0;
      return isValid ? null : { noEmptyAfterTrim: true };
    };
  }

  linkedinUrlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dto = new StartupApplyFormValidationDto();
      dto.linkedinUrl = control.value;
      const errors = validateSync(dto);
      if (errors.length > 0) {
        return { linkedinUrl: 'Invalid LinkedIn URL' };
      }
      return null;
    };
  }

  setTitle() {
    this.titleService.setTitle(`Codenteam - Startup - Apply`);
  }

  checkLinkValidity() {
    this.investorHubService
      .checkStartupApplyLinkValidity(this.token)
      .subscribe((res) => {
        if (res) {
          this.isInValid = false;
          this.accountUuid = res.uuid;
          this.slug = res.slug.toUpperCase();
          (this.instantInvite = res.instantInvite), (this.userId = res.userId);
        } else {
          this.openInvalidUrlDialog();
        }
      });
  }

  openInvalidUrlDialog() {
    this.isInValid = true;
    this.dialogRef.open(PopupModelComponent, {
      data: {
        name: 'Invalid url',
        canClose: false,
      },
    });
  }

  browseForLogo() {
    this.fileInput.nativeElement.click();
  }

  handleLogoUpload(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];
    this.getLogoPreview(file);

    this.startupCoreService
      .uploadLogo(
        file,
        this.accountUuid,
        this.startupUuid ? this.startupUuid : null
      )
      .subscribe((res) => {
        this.startupUuid = res.startupUuid;
        this.startupDataForm.patchValue({
          logo: this.logoPreview,
        });
      });
  }

  getLogoPreview(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.logoPreview = reader.result;
      this.changeDetectorRefs.detectChanges();
    };
  }

  browseForFiles() {
    this.filesUpload.nativeElement.click();
  }

  handleFilesUpload(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const files = inputElement.files;
    this.previewUploadedFiles(files);
  }

  previewUploadedFiles(files: FileList) {
    if (this.files) {
      const filesArray = Array.from(files);

      filesArray.forEach((file) => {
        this.files.push(file);
      });
    } else {
      const filesArray = Array.from(files);
      this.files = filesArray;
    }
    //here should get all files name with ext and sace it in array of string in the form to send it with submit request

    const filesNamesArray = this.startupDataForm.get('filesNames') as FormArray;

    Array.from(files).forEach((file) => {
      if (file.size < 26214400) {
        filesNamesArray.push(new FormControl(file.name));
      } else {
        this.bigFiles.push(file);
      }
    });
  }

  submit() {
    this.applied = false;
    this.isSubmitting = true;
    const filesNames = (this.startupDataForm.get('filesNames') as FormArray)
      .value;
    const startupData: StartupDataInput = {
      uuid: this.startupUuid ? this.startupUuid : null,
      name: this.startupDataForm.value.companyName,
      email: this.startupDataForm.value.companyEmail,
      website: this.startupDataForm.value.companyWebsite,
      linkedinUrl: this.startupDataForm.value.linkedInUrl,
      description: this.startupDataForm.value.companyDescription,
      contactName: this.startupDataForm.value.contactName,
      contactEmail: this.startupDataForm.value.contactEmail,
      numberOfEmployees: this.startupDataForm.value
        .numberOfEmployees as EmployeesNumber,
      pentestScanUrl: this.startupDataForm.value.pentestScanUrl,
      filesNames: filesNames,
    };

    this.investorHubService
      .createNewStartup(
        startupData,
        this.accountUuid,
        this.instantInvite,
        this.userId
      )
      .subscribe({
        next: (res) => {
          this.isSubmitting = false;
          this.applied = true;
          //here if get array of uploadUrls should call function to upload this files to s3
          this.uploadSelectedFiled(res);
        },
        error: (err) => {
          this.isSubmitting = false;
          this.startupDataForm.controls.pentestScanUrl.setErrors({
            incorrect: true,
          });
          this.dialogRef.open(ErrorDialogComponent, {
            data: {
              message: 'Something wrong happened, Try again!',
            },
          });
        },
      });
  }

  uploadSelectedFiled(uploadUrls: string[]) {
    if (uploadUrls.length !== 0) {
      // Remove bigFiles from files array
      this.files = this.files.filter((file) => !this.bigFiles.includes(file));

      const dataTransfer = new DataTransfer();
      this.files.forEach((file) => {
        dataTransfer.items.add(file);
      });
      const filesArray = dataTransfer.files;
      this.startupCoreService.uploadFiles(filesArray, uploadUrls);
    }
  }
  removeFile(file: File) {
    const filteredFileArray = this.files.filter((item) => item !== file);
    this.files = filteredFileArray;
    const filesNamesArray = this.startupDataForm.get('filesNames') as FormArray;
    const removeIndex = filesNamesArray.controls.findIndex(
      (control) => control.value === file.name
    );
    if (removeIndex !== -1) {
      filesNamesArray.removeAt(removeIndex);
    }
  }

  checkFile(file: File) {
    if (this.bigFiles.find((item) => item === file)) {
      return true;
    } else return false;
  }
}
