var TYPE = require('../../tokenizer').TYPE;
var rawMode = require('./Raw').mode;
var LEFTCURLYBRACKET = TYPE.LeftCurlyBracket;
function consumeRaw(startToken) {
  return this.Raw(startToken, rawMode.leftCurlyBracket, true);
}
function consumePrelude() {
  var prelude = this.SelectorList();
  if (prelude.type !== 'Raw' && this.scanner.eof === false && this.scanner.tokenType !== LEFTCURLYBRACKET) {
    this.error();
  }
  return prelude;
}
module.exports = {
  name: 'Rule',
  structure: {
    prelude: ['SelectorList', 'Raw'],
    block: ['Block']
  },
  parse: function () {
    var startToken = this.scanner.tokenIndex;
    var startOffset = this.scanner.tokenStart;
    var prelude;
    var block;
    if (this.parseRulePrelude) {
      prelude = this.parseWithFallback(consumePrelude, consumeRaw);
    } else {
      prelude = consumeRaw.call(this, startToken);
    }
    block = this.Block(true);
    return {
      type: 'Rule',
      loc: this.getLocation(startOffset, this.scanner.tokenStart),
      prelude: prelude,
      block: block
    };
  },
  generate: function (node) {
    this.node(node.prelude);
    this.node(node.block);
  },
  walkContext: 'rule'
};