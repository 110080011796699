var TYPE = require('../../tokenizer').TYPE;
var COMMENT = TYPE.Comment;
var ASTERISK = 0x002A; // U+002A ASTERISK (*)
var SOLIDUS = 0x002F; // U+002F SOLIDUS (/)

// '/*' .* '*/'
module.exports = {
  name: 'Comment',
  structure: {
    value: String
  },
  parse: function () {
    var start = this.scanner.tokenStart;
    var end = this.scanner.tokenEnd;
    this.eat(COMMENT);
    if (end - start + 2 >= 2 && this.scanner.source.charCodeAt(end - 2) === ASTERISK && this.scanner.source.charCodeAt(end - 1) === SOLIDUS) {
      end -= 2;
    }
    return {
      type: 'Comment',
      loc: this.getLocation(start, this.scanner.tokenStart),
      value: this.scanner.source.substring(start + 2, end)
    };
  },
  generate: function (node) {
    this.chunk('/*');
    this.chunk(node.value);
    this.chunk('*/');
  }
};