var TYPE = require('../../tokenizer').TYPE;
var SEMICOLON = TYPE.Semicolon;
var LEFTCURLYBRACKET = TYPE.LeftCurlyBracket;
module.exports = {
  name: 'AtrulePrelude',
  structure: {
    children: [[]]
  },
  parse: function (name) {
    var children = null;
    if (name !== null) {
      name = name.toLowerCase();
    }
    this.scanner.skipSC();
    if (this.atrule.hasOwnProperty(name) && typeof this.atrule[name].prelude === 'function') {
      // custom consumer
      children = this.atrule[name].prelude.call(this);
    } else {
      // default consumer
      children = this.readSequence(this.scope.AtrulePrelude);
    }
    this.scanner.skipSC();
    if (this.scanner.eof !== true && this.scanner.tokenType !== LEFTCURLYBRACKET && this.scanner.tokenType !== SEMICOLON) {
      this.error('Semicolon or block is expected');
    }
    if (children === null) {
      children = this.createList();
    }
    return {
      type: 'AtrulePrelude',
      loc: this.getLocationFromList(children),
      children: children
    };
  },
  generate: function (node) {
    this.children(node);
  },
  walkContext: 'atrulePrelude'
};