var TYPE = require('../../tokenizer').TYPE;
var COMMA = TYPE.Comma;
module.exports = {
  name: 'SelectorList',
  structure: {
    children: [['Selector', 'Raw']]
  },
  parse: function () {
    var children = this.createList();
    while (!this.scanner.eof) {
      children.push(this.Selector());
      if (this.scanner.tokenType === COMMA) {
        this.scanner.next();
        continue;
      }
      break;
    }
    return {
      type: 'SelectorList',
      loc: this.getLocationFromList(children),
      children: children
    };
  },
  generate: function (node) {
    this.children(node, function () {
      this.chunk(',');
    });
  },
  walkContext: 'selector'
};