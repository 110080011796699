"use strict";

var isValue = require("type/value/is"),
  isPlainFunction = require("type/plain-function/is"),
  assign = require("es5-ext/object/assign"),
  normalizeOpts = require("es5-ext/object/normalize-options"),
  contains = require("es5-ext/string/#/contains");
var d = module.exports = function (dscr, value /*, options*/) {
  var c, e, w, options, desc;
  if (arguments.length < 2 || typeof dscr !== "string") {
    options = value;
    value = dscr;
    dscr = null;
  } else {
    options = arguments[2];
  }
  if (isValue(dscr)) {
    c = contains.call(dscr, "c");
    e = contains.call(dscr, "e");
    w = contains.call(dscr, "w");
  } else {
    c = w = true;
    e = false;
  }
  desc = {
    value: value,
    configurable: c,
    enumerable: e,
    writable: w
  };
  return !options ? desc : assign(normalizeOpts(options), desc);
};
d.gs = function (dscr, get, set /*, options*/) {
  var c, e, options, desc;
  if (typeof dscr !== "string") {
    options = set;
    set = get;
    get = dscr;
    dscr = null;
  } else {
    options = arguments[3];
  }
  if (!isValue(get)) {
    get = undefined;
  } else if (!isPlainFunction(get)) {
    options = get;
    get = set = undefined;
  } else if (!isValue(set)) {
    set = undefined;
  } else if (!isPlainFunction(set)) {
    options = set;
    set = undefined;
  }
  if (isValue(dscr)) {
    c = contains.call(dscr, "c");
    e = contains.call(dscr, "e");
  } else {
    c = true;
    e = false;
  }
  desc = {
    get: get,
    set: set,
    configurable: c,
    enumerable: e
  };
  return !options ? desc : assign(normalizeOpts(options), desc);
};