var noop = function () {};
function ensureFunction(value) {
  return typeof value === 'function' ? value : noop;
}
module.exports = function (node, options, context) {
  function walk(node) {
    enter.call(context, node);
    switch (node.type) {
      case 'Group':
        node.terms.forEach(walk);
        break;
      case 'Multiplier':
        walk(node.term);
        break;
      case 'Type':
      case 'Property':
      case 'Keyword':
      case 'AtKeyword':
      case 'Function':
      case 'String':
      case 'Token':
      case 'Comma':
        break;
      default:
        throw new Error('Unknown type: ' + node.type);
    }
    leave.call(context, node);
  }
  var enter = noop;
  var leave = noop;
  if (typeof options === 'function') {
    enter = options;
  } else if (options) {
    enter = ensureFunction(options.enter);
    leave = ensureFunction(options.leave);
  }
  if (enter === noop && leave === noop) {
    throw new Error('Neither `enter` nor `leave` walker handler is set or both aren\'t a function');
  }
  walk(node, context);
};