"use strict";

module.exports = function () {
  var from = Array.from,
    arr,
    result;
  if (typeof from !== "function") return false;
  arr = ["raz", "dwa"];
  result = from(arr);
  return Boolean(result && result !== arr && result[1] === "dwa");
};