<form
  [formGroup]="accountDataForm"
  class="mx-3 flex flex-col md:mx-5 lg:ml-0 lg:mr-[3.125rem]"
  (ngSubmit)="updateAccountData()"
>
  <div class="mb-4 flex items-center rounded-md bg-slate-900 px-5 py-2.5">
    <mat-icon>settings</mat-icon>
    <p class="ml-2 text-xl font-medium">Settings</p>
    <button
      class="ml-auto rounded-full border-2 border-blue-400 px-12 py-0.5 text-lg font-medium text-blue-400"
      type="submit"
      [disabled]="!accountDataForm.valid"
      [matTooltip]="
        !accountDataForm.valid ? 'Company name field is required' : null
      "
      [ngClass]="!isCurrentUserAdmin ? 'hidden' : 'block'"
    >
      Save
    </button>
  </div>

  <div class="grid grid-cols-12 gap-5">
    <!-- Company Description -->
    <div
      class="col-span-12 flex flex-col gap-5 rounded-xl bg-slate-900 p-5 lg:col-span-6"
    >
      <div class="flex flex-col items-center gap-6 md:flex-row">
        <div
          class="flex w-full flex-col gap-3 rounded-xl bg-slate-800 p-4 md:w-1/3"
        >
          <div
            class="flex justify-center rounded-md border-4 border-dashed border-slate-900 px-4 pb-3 pt-4"
          >
            <img
              *ngIf="logoPreview"
              [src]="logoPreview"
              alt="Logo"
              class="mx-auto !h-24 !w-24 object-contain"
            />

            <mat-icon
              *ngIf="!logoPreview"
              class="mx-auto !h-7 !w-7 !text-2xl !text-slate-900 xl:!h-9 xl:!w-9"
              svgIcon="upload-logo"
            ></mat-icon>
          </div>
          <div
            class="flex flex-col gap-2"
            *ngIf="isCurrentUserAdmin"
          >
            <button
              class="w-full rounded-md bg-gray-400 py-1 text-center text-base font-normal text-slate-900"
              (click)="browseForLogo()"
            >
              <input
                accept=".png,.svg,.JPG"
                type="file"
                #fileInput
                style="display: none"
                (change)="handleLogoUpload($event)"
              />
              {{ logoPreview ? 'Change' : 'Upload' }} your logo
            </button>
            <p
              class="text-center text-sm font-normal text-gray-400 md:text-[11px] lg:text-xs"
            >
              Supported: JPG, PNG, SVG
            </p>
          </div>
        </div>

        <div
          class="flex w-full flex-col gap-5 text-xl font-normal text-gray-400 md:w-2/3"
        >
          <div>
            <p>Company Name:</p>
            <input
              class="w-full rounded-md border-2 border-gray-600 bg-slate-900 px-3 py-1 text-base font-normal text-white"
              type="text"
              formControlName="companyName"
            />
          </div>
          <div>
            <p>Website:</p>
            <input
              class="w-full rounded-md border-2 border-gray-600 bg-slate-900 px-3 py-1 text-base font-normal text-white"
              type="email"
              formControlName="link"
            />
          </div>
        </div>
      </div>

      <div class="text-xl font-normal text-gray-400">
        <p>Location:</p>
        <input
          class="w-full rounded-md border-2 border-gray-600 bg-slate-900 px-3 py-1 text-base font-normal text-white"
          type="text"
          formControlName="location"
        />
      </div>

      <div class="flex h-full flex-col text-xl font-normal text-gray-400">
        <p class="pb-1">Company description:</p>
        <textarea
          class="min-h-[14rem] resize-none rounded-md border-2 border-gray-600 bg-slate-900 px-3 text-base font-normal text-white lg:min-h-max lg:flex-1"
          formControlName="companyDescription"
        ></textarea>
      </div>
    </div>

    <!-- Account Details -->
    <div class="col-span-12 flex flex-col gap-5 lg:col-span-6">
      <!-- Current Plan Information -->
      <div
        class="col-span-12 flex flex-col rounded-xl bg-slate-900 p-5 md:col-span-12 lg:col-span-7 xl:col-span-9"
      >
        <!-- Plan Information -->
        <div class="my-3 px-3">
          <p class="text-center text-lg font-light text-gray-400 md:text-2xl">
            Current plan name:
            <span class="text-white">{{ subscriptionData?.planName }}</span>
          </p>
          <div class="mt-3">
            <div class="custom-progress-bar grid grid-cols-12 gap-3 md:gap-4">
              <p class="col-span-4 flex text-[18px] font-light md:col-span-2">
                Lines<span class="ml-auto">:</span>
              </p>
              <mat-progress-bar
                class="col-span-8 my-auto !h-[5px] rounded-md md:col-span-7"
                mode="determinate"
                [value]="
                  (currentLinesCount / subscriptionData?.linesLimit) * 100
                "
                [ngClass]="
                  currentLinesCount > subscriptionData?.linesLimit
                    ? 'custom-progress-bar'
                    : 'mat-mdc-progress-bar'
                "
              ></mat-progress-bar>
              <p
                class="col-span-12 mt-[-1.25rem] flex items-center truncate text-xs font-normal text-gray-400 md:col-span-3 md:mt-0"
                [title]="currentLinesCount + '/' + subscriptionData?.linesLimit"
              >
                <span
                  [ngClass]="
                    currentLinesCount > subscriptionData?.linesLimit
                      ? 'text-red-700'
                      : ''
                  "
                  >{{ currentLinesCount }}</span
                >/{{ subscriptionData?.linesLimit }}
              </p>
            </div>
            <div class="grid grid-cols-12 gap-3 md:gap-4">
              <p class="col-span-4 flex text-[18px] font-light md:col-span-2">
                Authors<span class="ml-auto">:</span>
              </p>
              <mat-progress-bar
                class="col-span-8 my-auto !h-[5px] rounded-md md:col-span-7"
                mode="determinate"
                [ngClass]="
                  currentAuthorsCount > subscriptionData?.authorsLimit
                    ? 'custom-progress-bar'
                    : 'mat-mdc-progress-bar'
                "
                [value]="
                  (currentAuthorsCount / subscriptionData?.authorsLimit) * 100
                "
              ></mat-progress-bar>
              <p
                class="col-span-12 mt-[-1.25rem] flex items-center truncate text-xs font-normal text-gray-400 md:col-span-3 md:mt-0"
                [title]="
                  currentAuthorsCount + '/' + subscriptionData?.authorsLimit
                "
              >
                <span
                  [ngClass]="
                    currentAuthorsCount > subscriptionData?.authorsLimit
                      ? 'text-red-700'
                      : ''
                  "
                  >{{ currentAuthorsCount }}</span
                >/{{ subscriptionData?.authorsLimit }}
              </p>
            </div>
            <div class="grid grid-cols-12 gap-3 md:gap-4">
              <p class="col-span-4 flex text-[18px] font-light md:col-span-2">
                Users<span
                  class="ml-auto flex items-center justify-center text-center"
                  >:</span
                >
              </p>
              <mat-progress-bar
                class="col-span-8 my-auto !h-[5px] rounded-md md:col-span-7"
                mode="determinate"
                [value]="(users?.length / subscriptionData?.usersLimit) * 100"
                [ngClass]="
                  users?.length > subscriptionData?.usersLimit
                    ? 'custom-progress-bar'
                    : 'mat-mdc-progress-bar'
                "
              ></mat-progress-bar>
              <p
                class="col-span-12 mt-[-1.25rem] flex items-center truncate text-center text-xs font-normal text-gray-400 md:col-span-3 md:mt-0"
                [title]="users?.length + '/' + subscriptionData?.usersLimit"
              >
                <span
                  [ngClass]="
                    users?.length > subscriptionData?.usersLimit
                      ? 'text-red-700'
                      : ''
                  "
                  >{{ users?.length }}</span
                >/{{ subscriptionData?.usersLimit }}
              </p>
            </div>
            <div class="grid grid-cols-12 gap-3 md:gap-4">
              <p
                [matTooltip]="'Pentests/month'"
                class="col-span-4 flex text-[18px] font-light md:col-span-2"
              >
                <span class="max-w-80% truncate md:max-w-full"
                  >Pentests/month</span
                >
                <span
                  class="ml-auto flex items-center justify-center text-center"
                  >:</span
                >
              </p>
              <mat-progress-bar
                class="col-span-8 my-auto !h-[5px] rounded-md md:col-span-7"
                mode="determinate"
                [value]="
                  (currentPentestsCount / subscriptionData?.pentestsLimit) * 100
                "
                [ngClass]="
                  currentPentestsCount > subscriptionData?.pentestsLimit
                    ? 'custom-progress-bar'
                    : 'mat-mdc-progress-bar'
                "
              ></mat-progress-bar>
              <p
                class="col-span-12 mt-[-1.25rem] flex items-center truncate text-center text-xs font-normal text-gray-400 md:col-span-3 md:mt-0"
                [title]="
                  currentPentestsCount + '/' + subscriptionData?.pentestsLimit
                "
              >
                <span
                  [ngClass]="
                    currentPentestsCount > subscriptionData?.pentestsLimit
                      ? 'text-red-700'
                      : ''
                  "
                  >{{ currentPentestsCount }}</span
                >/{{ subscriptionData?.pentestsLimit }}
              </p>
            </div>
          </div>

          <!-- Expiration Date -->
          <div
            class="mt-3 flex flex-col items-center justify-center gap-1 text-center md:flex-row md:gap-5"
          >
            <p class="text-[15px] font-normal xl:text-lg">
              Expiration date:
              <span class="ml-1 font-semibold">{{
                subscriptionData?.expirationDate | date
              }}</span>
            </p>
            <p class="text-lg">
              (
              <span
                [ngClass]="daysRemaining > 7 ? 'text-blue-400' : 'text-red-800'"
                >{{ daysRemaining }} Days</span
              >
              remaining )
            </p>
          </div>
        </div>

        <!-- Manage Plans Button -->
        <button
          *ngIf="
            accountData?.ownerId === currentUserId &&
            subscriptionData?.customerId
          "
          class="mt-auto h-10 w-full rounded-[5px] bg-rose-500 px-11 py-2.5"
          (click)="moveToCustomerPortal()"
        >
          Manage Plans
        </button>
      </div>

      <!-- Account Users -->
      <div class="">
        <p
          class="rounded-t-xl bg-slate-900 py-1 text-center text-2xl font-normal text-gray-400"
        >
          Account mails:
          <span class="ml-5 text-xl font-medium"
            >({{ users?.length }}/{{ subscriptionData?.usersLimit }})
            Users</span
          >
        </p>
        <div class="bg-slate-700 p-4">
          <div class="container flex flex-col gap-4">
            <div *ngFor="let user of users; index as i">
              <div class="flex h-[35px]">
                <h1 class="mr-5 mt-2 font-bold text-blue-200">{{ i + 1 }}.</h1>
                <div class="mb-3">
                  <div
                    class="relative mr-5 h-9 w-9 overflow-hidden rounded-full !bg-slate-900 !text-white"
                  >
                    <mat-icon
                      class="!h-12 !w-auto"
                      svgIcon="single-developer"
                    ></mat-icon>
                  </div>
                </div>
                <h2
                  class="text-md truncate pt-2 !text-base font-medium md:max-w-none"
                >
                  {{ user?.email }}
                </h2>
                <div
                  *ngIf="user?.isAdmin"
                  class="mt-2"
                >
                  <span
                    class="ml-3 rounded bg-yellow-400 px-2 py-0.5 text-xs font-medium text-black"
                    >Admin</span
                  >
                </div>
                <div
                  *ngIf="isCurrentUserAdmin && currentUserId !== user.id"
                  class="!ml-auto"
                >
                  <button
                    class="ml-auto flex"
                    [matMenuTriggerFor]="menu"
                  >
                    <mat-icon class="mb-1 !text-gray-500 hover:!text-gray-300">
                      more_vert
                    </mat-icon>
                  </button>
                  <mat-menu
                    #menu="matMenu"
                    class="!rounded-2xl !bg-slate-800"
                    xPosition="after"
                    yPosition="below"
                  >
                    <button
                      *ngIf="user.isAdmin === false"
                      mat-menu-item
                      class="!text-blue-200"
                      (click)="addAdmin(user)"
                    >
                      Mark user as an admin
                    </button>
                    <button
                      *ngIf="
                        user.isAdmin &&
                        isCurrentUserAdmin &&
                        currentUserId !== user.id
                      "
                      mat-menu-item
                      class="!text-blue-200"
                      (click)="removeAdmin(user.id)"
                    >
                      Remove admin
                    </button>
                    <div class="flex items-center justify-center">
                      <mat-divider
                        class="h-0.5 w-4/5 bg-slate-700"
                      ></mat-divider>
                    </div>
                    <button
                      *ngIf="isCurrentUserAdmin && currentUserId !== user.id"
                      mat-menu-item
                      class="!text-blue-200"
                      (click)="removeUserFromAccount(user.id)"
                    >
                      Remove User
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rounded-b-xl bg-slate-900 px-5 py-3">
          <h1 class="mb-2 text-center text-base font-normal text-blue-200">
            Add new user to your account
          </h1>

          <form
            [formGroup]="emailForm"
            class="mb-2 flex !gap-[10px]"
            (ngSubmit)="onSubmit()"
          >
            <input
              [class.is-invalid]="
                emailForm.get('email').invalid && emailForm.get('email').touched
              "
              class="form-input w-full rounded-full bg-slate-800 px-4 py-1"
              placeholder="email.domain@ltd"
              formControlName="email"
              type="email"
              required
            />
            <button
              class="text-md ml-auto rounded-3xl !bg-blue-400 px-4 !leading-5"
              type="submit"
              [disabled]="email.invalid"
            >
              Submit
            </button>
          </form>

          <h2 class="text-[9px] text-gray-400 md:text-xs">
            Note: We will send a verification email to confirm the new user.
          </h2>
        </div>
      </div>
    </div>
  </div>

  <!-- automatic updates -->
  <div
    class="mt-4 flex flex-col gap-3 rounded-3xl bg-slate-900 p-5"
    formGroupName="accountSettingsDataForm"
    *ngIf="
      ('automated-updates' | featureflag$ | async) === true &&
      isBetweenSubscriptionDates() &&
      subscriptionData.planName !== 'Free'
    "
  >
    <mat-checkbox
      formControlName="allowAutomatedUpdates"
      (change)="updateAllowAutomatedUpdates($event)"
      >Allow automated updates</mat-checkbox
    >
    <div class="ml-3 text-gray-400">
      Note: your runs will update automatically, keeping your information fresh
      without needing to do it yourself.
    </div>
  </div>
</form>
