import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SafeHtml } from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table';
import { combineLatest, map, Observable, of } from 'rxjs';
import { LetDirective } from '@ngrx/component';
import { SpiderChartComponent } from '@codenteam/ui/spider-chart/spider-chart.component';
import { TeamsService } from '../../core/teams.service';
import { FilesService } from '../../core/files.service';
import { LinesService } from '../../core/lines.service';
import { ApiService } from '../../core/api.service';
import { Utils } from '../../core/utils';
import { HistoricalResultValue, OwnerType } from '@codenteam/portal/graphql';
import { NameValue, Team } from '@codenteam/portal/graphql';
import { ReportHeaderComponent } from '@codenteam/ui/report/report-header/report-header.component';
import { PieGraphComponent } from '@codenteam/ui/pie-graph/pie-graph.component';
import { ReportService } from '../report.service';

export interface PeriodicElement {
  metric: string | SafeHtml;
  now: number;
  was: number | string;
  notes: string | SafeHtml;
}

@Component({
  selector: 'codenteam-teams-analysis-report',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTableModule,
    SpiderChartComponent,
    LetDirective,
    ReportHeaderComponent,
    PieGraphComponent,
  ],
  templateUrl: './teams-analysis-report.component.html',
  styleUrl: './teams-analysis-report.component.scss',
})
export class TeamsAnalysisReportComponent implements OnInit {
  displayedColumns: string[] = ['metric', 'now', 'was', 'notes'];
  dataSource: PeriodicElement[] = [];

  @Input()
  runId: string;
  @Input()
  team: Team;

  @Output()
  completed = new EventEmitter<boolean>();

  report$: Observable<any>;
  spiderTeams: { name: string; color: string; rate1: number; rate2: number }[];
  numberOfRisks: number = 0;
  numberOfRisksLastMonth: number = 0;
  currentDate: string = this.reportService.getDate().currentDate;
  lastMonthDate: string = this.reportService.getDate().lastMonthDate;
  account$ = this.apiService.getAccountData();
  userInfo$ = this.apiService.getUserInfo();
  teamThisMonth: NameValue[];
  teamPreviousMonth: NameValue[];
  numberOfExEmployees: number;
  numberOfAllModules: number;
  numberOfFiles: number;
  haveValue = (object: NameValue[]): boolean =>
    this.reportService.objectHasValue(object);

  table$: Observable<
    {
      metric: string | SafeHtml;
      now: number;
      was: string | number;
      notes: SafeHtml;
    }[]
  >;

  @Input()
  logoPreview$: Observable<string | ArrayBuffer>;

  constructor(
    private teamsService: TeamsService,
    private filesService: FilesService,
    private linesService: LinesService,
    private apiService: ApiService,
    private reportService: ReportService
  ) {}

  ngOnInit(): void {
    this.fetchAll();
  }
  fetchAll() {
    const profileWithHighestLinesInTeam$ = this.linesService
      .contributionsPer$({
        range$: of({ start: null, end: null }),
        per: 'profile',
        teamId$: of(this.team.id),
        runId$: of(this.runId),
      })
      .pipe(
        map((profilesContribution) => {
          if (profilesContribution.length === 0) {
            return null;
          }
          return profilesContribution.reduce((maxProfile, profile) =>
            maxProfile.value > profile.value ? maxProfile : profile
          );
        })
      );

    const linesPerExInTeam$ = this.linesService.contributionsPer$({
      range$: of({ start: null, end: null }),
      per: 'ex',
      teamId$: of(this.team.id),
      runId$: of(this.runId),
    });
    const linesPerExInTeamBefore30Days$ = this.linesService.contributionsPer$({
      range$: of({
        start: null,
        end:
          new Date(new Date().setMonth(new Date().getMonth() - 1)).getTime() /
          1000,
      }),
      per: 'ex',
      teamId$: of(this.team.id),
      runId$: of(this.runId),
    });
    const numberOfDirectories$ = this.teamsService
      .getNumberOfDirectories(of(this.team.id), of(this.runId))
      .pipe(
        map((res) => {
          if (res) {
            return { value: res };
          } else {
            return { value: 0 };
          }
        })
      );
    const linesPerProfilesLasMonth$ = this.linesService.contributionsPer$({
      range$: of({
        start:
          new Date(new Date().setMonth(new Date().getMonth() - 1)).getTime() /
          1000,
        end: new Date().getTime() / 1000,
      }),
      per: 'profile',
      teamId$: of(this.team.id),
      runId$: of(this.runId),
    });

    const linesPerProfiles$ = this.linesService.contributionsPer$({
      range$: of({ start: null, end: null }),
      per: 'profile',
      teamId$: of(this.team.id),
      runId$: of(this.runId),
    });

    const numberOfFiles$ = this.filesService.getFilesCount(of(this.runId), {
      teamId$: of(this.team.id),
    });

    const ExEmployeesOfTeam$ = this.apiService.getExEmployeesInTeam(
      this.runId,
      this.team.id
    );

    this.table$ = combineLatest([
      this.apiService.getReportClosestTo30DaysAgo(this.runId),
      numberOfDirectories$,
      numberOfFiles$,
      linesPerExInTeam$,
      this.apiService.directoriesContributedBeforeLast30Days(this.runId, {
        teamId: this.team.id,
      }),
      this.apiService.filesContributedBeforeLast30Days(this.runId, {
        teamId: this.team.id,
      }),
      linesPerProfiles$,
      linesPerExInTeamBefore30Days$,
      linesPerProfilesLasMonth$,
      ExEmployeesOfTeam$,
    ]).pipe(
      map(
        ([
          report,
          numberOfDirectories,
          numberOfFiles,
          linesPerExInTeam,
          directoriesContributedBeforeLast30Days,
          filesContributedBeforeLast30Days,
          linesPerProfiles,
          linesPerExInTeamBefore30Days,
          linesPerProfilesLasMonth,
          exEmployeesInTeam,
        ]) => {
          // get number of files

          this.numberOfFiles = numberOfFiles.value;

          //get number of ex-employees

          this.numberOfExEmployees = exEmployeesInTeam.length;

          //get exContribution (now) value

          const exContributionValue = linesPerExInTeam[1]
            ? linesPerExInTeam[1].value
            : 0;

          //get exContribution (was) value

          const exContributionBefore30Days = linesPerExInTeamBefore30Days[1]
            ? linesPerExInTeamBefore30Days[1].value
            : 0;

          //get the total team contribution (now) value

          const totalTeamContribution = linesPerExInTeam.reduce(
            (sum: number, ex: any) => sum + ex.value,
            0
          );

          //get the total team contribution (was) value

          const numberOfLinesBeforeLast30Days =
            linesPerExInTeamBefore30Days.reduce(
              (sum: number, ex: any) => sum + ex.value,
              0
            );

          //fill spiderTeams array for spider chart if number of team is more than 2
          if (this.team.profiles.length > 2) {
            this.spiderTeams = this.team.profiles.map((profile) => {
              const profileLastMonth = Utils.resolveHistoricalValue(
                report,
                'profile',
                'total',
                profile.id
              );
              let rateLastMonth = 0;
              let rateNow = 0;
              const profileLines =
                linesPerProfiles.find((item) => item.id === profile.id)
                  ?.value ?? 0;
              if (numberOfLinesBeforeLast30Days > 0) {
                rateNow = parseFloat(
                  ((profileLines / totalTeamContribution) * 100).toFixed()
                );
                if (profileLastMonth.teamId === this.team.id) {
                  rateLastMonth = parseFloat(
                    (
                      (profileLastMonth.value / numberOfLinesBeforeLast30Days) *
                      100
                    ).toFixed()
                  );
                }
              }
              return {
                id: profile.id,
                name: profile.name,
                color: profile.color,
                rate1: rateNow,
                rate2: rateLastMonth,
              };
            });
          } else {
            //fill teamThisMonth and teamPreviousMonth arrays for pie chart if number of team is equal or less than 2

            this.teamThisMonth = linesPerProfiles.map((profile) => {
              let rateNow = 0;
              rateNow = parseFloat(
                ((profile.value / totalTeamContribution) * 100).toFixed()
              );

              return {
                id: profile.id,
                name: profile.name,
                value: rateNow,
                color: profile.color,
              } as NameValue;
            });
            this.teamPreviousMonth = linesPerProfiles.map((profile) => {
              let rate = 0;
              const profileLastMonth = Utils.resolveHistoricalValue(
                report,
                'profile',
                'total',
                profile.id
              );

              if (
                numberOfLinesBeforeLast30Days > 0 &&
                profileLastMonth.teamId === this.team.id
              ) {
                rate = parseFloat(
                  (
                    (profileLastMonth.value / numberOfLinesBeforeLast30Days) *
                    100
                  ).toFixed()
                );
              }

              return {
                id: profile.id,
                name: profile.name,
                value: rate,
                color: profile.color,
              } as NameValue;
            });
          }

          //get number of developers (was) value
          const teamProfilesLastMonth = report.code.month.profile.filter(
            (profile) => profile.teamId === this.team.id
          );
          const numberOfProfilesBefore30Days = teamProfilesLastMonth.length;

          //get Average Contribution per Profile (now) value

          const averageContributionPerProfile =
            totalTeamContribution / this.team.profiles.length;

          //get Average Contribution per Profile (was) value

          const averageContributionPerProfileBefore30Days =
            numberOfProfilesBefore30Days > 0
              ? numberOfLinesBeforeLast30Days / numberOfProfilesBefore30Days
              : 0;

          //get highest contributing profile (now) value

          const profileWithHighestLinesInTeam = linesPerProfiles.reduce(
            (max, item) => (item.value > max.value ? item : max),
            {
              value: null,
            }
          );

          //get highest contributing profile (was) value

          const contributionBeforeLast30DaysForHighestProfile =
            teamProfilesLastMonth && teamProfilesLastMonth.length > 0
              ? teamProfilesLastMonth.reduce((max, item) =>
                  item.value > max.value ? item : max
                )
              : null;

          //get highest profile work factor (now) value
          const highestProfileWithWorkFactor = linesPerProfilesLasMonth.map(
            (profile) => {
              const currentProfile = this.team.profiles.find(
                (prof) => prof.id === profile.id
              );
              const workFactor =
                currentProfile.hoursPerWeek && currentProfile.costPerHour
                  ? profile.value /
                    (currentProfile?.hoursPerWeek *
                      4 *
                      currentProfile?.costPerHour)
                  : 0;

              return { ...profile, workFactor } as HistoricalResultValue;
            }
          );
          const highestProfileForWorkFactor =
            this.reportService.getHighestObjectWorkFactor(
              highestProfileWithWorkFactor
            );

          //get highest profile work factor (was) value
          const highestProfileForWorkFactorBefore30Days =
            teamProfilesLastMonth && teamProfilesLastMonth.length > 0
              ? this.reportService.getHighestObjectWorkFactor(
                  teamProfilesLastMonth
                )
              : null;
          //get total team work factor (now) value

          const totalTeamWorkFactor = highestProfileWithWorkFactor.reduce(
            (sum: number, profile: any) => {
              const workFactor =
                profile.workFactor / highestProfileWithWorkFactor.length;
              return sum + workFactor;
            },
            0
          );

          //get total team work factor (was) value

          const totalTeamWorkFactorBeforeLast30Days =
            report.code.month.team.find((item) => item.id === this.team.id)
              ?.workFactor ?? 0;

          //add risk if ex employees contribution percentage is more than 50%

          const exOwnerShipPercentage = Math.floor(
            Math.min((exContributionValue * 100) / totalTeamContribution, 100)
          );
          let exContributionNote = '';
          if (exOwnerShipPercentage >= 50) {
            this.numberOfRisks++;
            exContributionNote = `<div><span class="text-red-800">High-risk</span> team ownership (<span class="text-red-800">${exOwnerShipPercentage.toFixed(
              2
            )}%</span>)</div>`;
          }
          //add risk if ex employees contribution percentage is more than 50% (last month)

          const exOwnerShipPercentageLastMonth = Math.floor(
            Math.min(
              (exContributionBefore30Days * 100) /
                numberOfLinesBeforeLast30Days,
              100
            )
          );
          if (exOwnerShipPercentageLastMonth >= 50) {
            this.numberOfRisksLastMonth++;
          }
          //add risk if current employee contribution percentage is more than 50%

          let profileOwnTeamCode;
          let profileOwnTeamCodePercentage;
          linesPerProfiles.forEach((profile) => {
            const profileContributionPercentage = Math.floor(
              Math.min((profile.value * 100) / totalTeamContribution, 100)
            );
            if (profileContributionPercentage >= 50) {
              this.numberOfRisks++;
              profileOwnTeamCode = profile;
              profileOwnTeamCodePercentage = profileContributionPercentage;
            }
          });

          //add risk if current employee contribution percentage is more than 50% (last Month)

          report.code.total.profile.forEach((profile) => {
            if (profile.teamId === this.team.id) {
              const profileContributionPercentageLastMonth = Math.floor(
                Math.min(
                  (profile.value * 100) / numberOfLinesBeforeLast30Days,
                  100
                )
              );
              if (profileContributionPercentageLastMonth >= 50) {
                this.numberOfRisksLastMonth++;
              }
            }
          });

          //calculate number of modules owned by ex-employees (now) value

          // const numberOfOwnedModulesByExEmployees = exEmployeesInTeam.reduce(
          //   (sum: number, profile: any) => {
          //     const currProfile = profilesDarkModules.group.find(
          //       (item) => item.id === profile.id
          //     );
          //     return sum + currProfile.darkModulesNumber;
          //   },
          //   0
          // );
          // this.numberOfRisks += numberOfOwnedModulesByExEmployees;
          // this.numberOfAllModules = profilesDarkModules.allModules;

          //calculate number of modules owned by employees (now) value

          // const numberOfOwnedModulesByCurrentEmployees =
          //   profilesDarkModules.group.reduce(
          //     (sum: number, profile: any) =>
          //       profile.ex === false ? sum + profile.darkModulesNumber : sum,
          //     0
          //   );

          const numberOfLinesNote = this.reportService.calculatePercentage(
            totalTeamContribution,
            numberOfLinesBeforeLast30Days,
            false
          );

          const workFactorNote = this.reportService.calculatePercentage(
            totalTeamWorkFactor,
            totalTeamWorkFactorBeforeLast30Days,
            false
          );
          const highestProfileForWorkFactorNote =
            this.reportService.calculatePercentage(
              highestProfileForWorkFactor?.workFactor,
              highestProfileForWorkFactorBefore30Days?.workFactor,
              false
            );

          const highestContributingProfileNote =
            this.reportService.highestContributingProfileNoteFn(
              profileWithHighestLinesInTeam,
              contributionBeforeLast30DaysForHighestProfile,
              profileOwnTeamCode,
              profileOwnTeamCodePercentage,
              'team'
            );

          const numberOfDirectoriesNote =
            this.reportService.calculatePercentage(
              numberOfDirectories.value,
              directoriesContributedBeforeLast30Days,
              false
            );

          const averageContributionPerProfileNote =
            this.reportService.calculatePercentage(
              averageContributionPerProfile,
              averageContributionPerProfileBefore30Days,
              false
            );

          this.completed.emit(true);

          return [
            {
              metric: 'Number of Directories',
              now: numberOfDirectories.value,
              was: directoriesContributedBeforeLast30Days,
              notes: numberOfDirectoriesNote,
            },
            {
              metric: 'Number of Files',
              now: numberOfFiles.value,
              was: filesContributedBeforeLast30Days,
              notes: '',
            },
            {
              metric: 'Number of Lines',
              now: totalTeamContribution,
              was: numberOfLinesBeforeLast30Days,
              notes: numberOfLinesNote,
            },
            {
              metric: 'Number of Developers',
              now: linesPerProfiles.length,
              was: numberOfProfilesBefore30Days,
              notes: '',
            },
            {
              metric: 'Average Contribution per Developer',
              now: parseFloat(averageContributionPerProfile.toFixed(2)),
              was: parseFloat(
                averageContributionPerProfileBefore30Days.toFixed(2)
              ),
              notes: averageContributionPerProfileNote,
            },
            {
              metric: this.reportService.sanitizeHtml(`
                Highest Contributing Developer : <span style="color: ${profileWithHighestLinesInTeam.color};">${profileWithHighestLinesInTeam.name}</span>`),
              now: profileWithHighestLinesInTeam?.value,
              was: contributionBeforeLast30DaysForHighestProfile?.value,
              notes: highestContributingProfileNote,
            },
            {
              metric: 'Ex-Employee Ownership',
              now: exContributionValue,
              was: exContributionBefore30Days,
              notes: exContributionNote,
            },
            // {
            //   metric: 'Modules Owned By Ex-Employees',
            //   now: numberOfOwnedModulesByExEmployees,
            //   was: 0,
            //   notes: this.ownedModulesNoteFn(
            //     'ex-Employee',
            //     numberOfOwnedModulesByExEmployees
            //   ),
            // },
            // {
            //   metric: 'Modules owned by a single developer',
            //   now: numberOfOwnedModulesByCurrentEmployees,
            //   was: 0,
            //   notes: this.reportService.ownedModulesNoteFn(
            //     'developer',
            //     numberOfOwnedModulesByCurrentEmployees,
            //
            //   ),
            // },

            {
              metric: 'Work Factor',
              now: parseFloat(totalTeamWorkFactor.toFixed(2)),
              was: parseFloat(totalTeamWorkFactorBeforeLast30Days.toFixed(2)),
              notes: workFactorNote,
            },
            {
              metric: highestProfileForWorkFactor
                ? this.reportService.sanitizeHtml(`
                    Highest Developer in Team for Work Factor : <span style="color:${highestProfileForWorkFactor.color};">${highestProfileForWorkFactor.name}</span>`)
                : 'Highest Developer in Team for Work Factor',
              now: parseFloat(
                highestProfileForWorkFactor?.workFactor.toFixed(2)
              ),
              was: parseFloat(
                highestProfileForWorkFactorBefore30Days?.workFactor.toFixed(2)
              ),
              notes: highestProfileForWorkFactorNote,
            },
          ];
        }
      )
    );
  }
}
