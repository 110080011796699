var COMMA = require('../../tokenizer').TYPE.Comma;
module.exports = {
  name: 'MediaQueryList',
  structure: {
    children: [['MediaQuery']]
  },
  parse: function (relative) {
    var children = this.createList();
    this.scanner.skipSC();
    while (!this.scanner.eof) {
      children.push(this.MediaQuery(relative));
      if (this.scanner.tokenType !== COMMA) {
        break;
      }
      this.scanner.next();
    }
    return {
      type: 'MediaQueryList',
      loc: this.getLocationFromList(children),
      children: children
    };
  },
  generate: function (node) {
    this.children(node, function () {
      this.chunk(',');
    });
  }
};