module.exports = {
  name: 'Value',
  structure: {
    children: [[]]
  },
  parse: function () {
    var start = this.scanner.tokenStart;
    var children = this.readSequence(this.scope.Value);
    return {
      type: 'Value',
      loc: this.getLocation(start, this.scanner.tokenStart),
      children: children
    };
  },
  generate: function (node) {
    this.children(node);
  }
};