var List = require('../common/List');
module.exports = function createConvertors(walk) {
  return {
    fromPlainObject: function (ast) {
      walk(ast, {
        enter: function (node) {
          if (node.children && node.children instanceof List === false) {
            node.children = new List().fromArray(node.children);
          }
        }
      });
      return ast;
    },
    toPlainObject: function (ast) {
      walk(ast, {
        leave: function (node) {
          if (node.children && node.children instanceof List) {
            node.children = node.children.toArray();
          }
        }
      });
      return ast;
    }
  };
};