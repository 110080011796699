"use strict";

var numberIsNaN = require("../../number/is-nan"),
  toPosInt = require("../../number/to-pos-integer"),
  value = require("../../object/valid-value"),
  indexOf = Array.prototype.indexOf,
  objHasOwnProperty = Object.prototype.hasOwnProperty,
  abs = Math.abs,
  floor = Math.floor;
module.exports = function (searchElement /*, fromIndex*/) {
  var i, length, fromIndex, val;
  if (!numberIsNaN(searchElement)) return indexOf.apply(this, arguments);
  length = toPosInt(value(this).length);
  fromIndex = arguments[1];
  if (isNaN(fromIndex)) fromIndex = 0;else if (fromIndex >= 0) fromIndex = floor(fromIndex);else fromIndex = toPosInt(this.length) - floor(abs(fromIndex));
  for (i = fromIndex; i < length; ++i) {
    if (objHasOwnProperty.call(this, i)) {
      val = this[i];
      if (numberIsNaN(val)) return i; // Jslint: ignore
    }
  }
  return -1;
};