"use strict";

var global = require("ext/global-this"),
  validTypes = {
    object: true,
    symbol: true
  };
module.exports = function () {
  var Symbol = global.Symbol;
  var symbol;
  if (typeof Symbol !== "function") return false;
  symbol = Symbol("test symbol");
  try {
    String(symbol);
  } catch (e) {
    return false;
  }

  // Return 'true' also for polyfills
  if (!validTypes[typeof Symbol.iterator]) return false;
  if (!validTypes[typeof Symbol.toPrimitive]) return false;
  if (!validTypes[typeof Symbol.toStringTag]) return false;
  return true;
};