var List = require('../common/List');
module.exports = function clone(node) {
  var result = {};
  for (var key in node) {
    var value = node[key];
    if (value) {
      if (Array.isArray(value) || value instanceof List) {
        value = value.map(clone);
      } else if (value.constructor === Object) {
        value = clone(value);
      }
    }
    result[key] = value;
  }
  return result;
};