<mat-divider
  *ngIf="expanded && showDivider"
  class="!mx-3 min-w-9 rounded-full border-[2px] !border-sky-900"
></mat-divider>

<mat-expansion-panel
  class="mat-elevation-z0"
  *ngIf="list && list.length > 0 && show"
  [(expanded)]="expanded"
>
  <mat-expansion-panel-header
    *ngIf="toggleSide"
    class="!h-7"
  >
    <mat-panel-title class="!ml-4">
      <mat-icon
        [svgIcon]="headerIcon"
        *ngIf="headerIcon"
      ></mat-icon>
      <span class="header-text !text-white">{{ headerName }}</span>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-nav-list>
    <ng-container *ngFor="let item of list">
      <!--If item have childrens, it will work as expansion panel-->
      <mat-expansion-panel
        *ngIf="item.childrens && item.childrens.length > 0"
        class="min-w-[3.75rem]"
      >
        <mat-expansion-panel-header
          [ngClass]="toggleSide ? '!ml-4 !h-8' : ''"
          class="!transition-none"
        >
          <mat-panel-title>
            <mat-icon
              matListItemIcon
              [svgIcon]="item.icon"
              [ngClass]="{ '!text-white': !item.color }"
              [style.color]="item.color + ' !important'"
              matTooltip="{{ item.name }}"
              class="!ml-5 !mr-[10px] !h-5 !w-5"
            ></mat-icon>
            <span *ngIf="toggleSide">{{ item.name }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-nav-list [ngClass]="toggleSide ? '!ml-4' : ''">
          <mat-list-item
            *ngFor="let children of item.childrens"
            class="!h-8"
            trackClick="choose-profile-from-team"
            (click)="navigateToUrl(children.path)"
          >
            <mat-icon
              matListItemIcon
              [svgIcon]="children.icon"
              [ngClass]="{ '!text-white': !children.color }"
              [style.color]="children.color + ' !important'"
              matTooltip="{{ children.name }}"
              class="!ml-5 !mr-[10px] !h-5 !w-5"
            ></mat-icon>

            <a
              matListItemTitle
              *ngIf="toggleSide"
              >{{ children.name }}</a
            >
          </mat-list-item>
        </mat-nav-list>
      </mat-expansion-panel>

      <!--If the item does not have childrens, it will work as a normal list item.-->
      <mat-list-item
        *ngIf="
          (!item.childrens || item.childrens.length === 0) &&
          (item.flag | async) !== false
        "
        class="!h-8"
        trackClick="choose-team-from-sidenav"
        [ngClass]="toggleSide ? '!ml-4' : ''"
        (click)="navigateToUrl(item.path)"
        [matTooltip]="
          isProfileNotAssigned && item.name === 'Teams'
            ? 'You have unassigned profiles'
            : item.name === 'Profiles & Organizations' && isAuthorNotAssigned
            ? 'You have unassigned authors'
            : item.name
        "
      >
        <mat-icon
          matListItemIcon
          [svgIcon]="item.icon"
          [ngClass]="{ '!text-white': !item.color }"
          [style.color]="item.color + ' !important'"
          class="relative !ml-5 !mr-[10px] !h-5 !w-5"
          ><div
            class="absolute left-0 top-0 h-2 w-2 rounded-full bg-red-500"
            *ngIf="
              (item.name === 'Teams' && isProfileNotAssigned) ||
              (item.name === 'Profiles & Organizations' && isAuthorNotAssigned)
            "
          ></div
        ></mat-icon>

        <a
          matListItemTitle
          *ngIf="toggleSide"
          >{{ item.name }}</a
        >
      </mat-list-item>
    </ng-container>
  </mat-nav-list>
</mat-expansion-panel>
