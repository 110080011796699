"use strict";

var d = require("d"),
  validateSymbol = require("../../../validate-symbol");
var registry = Object.create(null);
module.exports = function (SymbolPolyfill) {
  return Object.defineProperties(SymbolPolyfill, {
    for: d(function (key) {
      if (registry[key]) return registry[key];
      return registry[key] = SymbolPolyfill(String(key));
    }),
    keyFor: d(function (symbol) {
      var key;
      validateSymbol(symbol);
      for (key in registry) {
        if (registry[key] === symbol) return key;
      }
      return undefined;
    })
  });
};