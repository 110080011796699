module.exports = {
  'dir': require('./dir'),
  'has': require('./has'),
  'lang': require('./lang'),
  'matches': require('./matches'),
  'not': require('./not'),
  'nth-child': require('./nth-child'),
  'nth-last-child': require('./nth-last-child'),
  'nth-last-of-type': require('./nth-last-of-type'),
  'nth-of-type': require('./nth-of-type'),
  'slotted': require('./slotted')
};