// CSS Syntax Module Level 3
// https://www.w3.org/TR/css-syntax-3/
var TYPE = {
  EOF: 0,
  // <EOF-token>
  Ident: 1,
  // <ident-token>
  Function: 2,
  // <function-token>
  AtKeyword: 3,
  // <at-keyword-token>
  Hash: 4,
  // <hash-token>
  String: 5,
  // <string-token>
  BadString: 6,
  // <bad-string-token>
  Url: 7,
  // <url-token>
  BadUrl: 8,
  // <bad-url-token>
  Delim: 9,
  // <delim-token>
  Number: 10,
  // <number-token>
  Percentage: 11,
  // <percentage-token>
  Dimension: 12,
  // <dimension-token>
  WhiteSpace: 13,
  // <whitespace-token>
  CDO: 14,
  // <CDO-token>
  CDC: 15,
  // <CDC-token>
  Colon: 16,
  // <colon-token>     :
  Semicolon: 17,
  // <semicolon-token> ;
  Comma: 18,
  // <comma-token>     ,
  LeftSquareBracket: 19,
  // <[-token>
  RightSquareBracket: 20,
  // <]-token>
  LeftParenthesis: 21,
  // <(-token>
  RightParenthesis: 22,
  // <)-token>
  LeftCurlyBracket: 23,
  // <{-token>
  RightCurlyBracket: 24,
  // <}-token>
  Comment: 25
};
var NAME = Object.keys(TYPE).reduce(function (result, key) {
  result[TYPE[key]] = key;
  return result;
}, {});
module.exports = {
  TYPE: TYPE,
  NAME: NAME
};