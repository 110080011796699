var SyntaxReferenceError = require('./error').SyntaxReferenceError;
var SyntaxMatchError = require('./error').SyntaxMatchError;
var names = require('../utils/names');
var generic = require('./generic');
var parse = require('../definition-syntax/parse');
var generate = require('../definition-syntax/generate');
var walk = require('../definition-syntax/walk');
var prepareTokens = require('./prepare-tokens');
var buildMatchGraph = require('./match-graph').buildMatchGraph;
var matchAsTree = require('./match').matchAsTree;
var trace = require('./trace');
var search = require('./search');
var getStructureFromConfig = require('./structure').getStructureFromConfig;
var cssWideKeywords = buildMatchGraph('inherit | initial | unset');
var cssWideKeywordsWithExpression = buildMatchGraph('inherit | initial | unset | <-ms-legacy-expression>');
function dumpMapSyntax(map, compact, syntaxAsAst) {
  var result = {};
  for (var name in map) {
    if (map[name].syntax) {
      result[name] = syntaxAsAst ? map[name].syntax : generate(map[name].syntax, {
        compact: compact
      });
    }
  }
  return result;
}
function dumpAtruleMapSyntax(map, compact, syntaxAsAst) {
  const result = {};
  for (const [name, atrule] of Object.entries(map)) {
    result[name] = {
      prelude: atrule.prelude && (syntaxAsAst ? atrule.prelude.syntax : generate(atrule.prelude.syntax, {
        compact
      })),
      descriptors: atrule.descriptors && dumpMapSyntax(atrule.descriptors, compact, syntaxAsAst)
    };
  }
  return result;
}
function valueHasVar(tokens) {
  for (var i = 0; i < tokens.length; i++) {
    if (tokens[i].value.toLowerCase() === 'var(') {
      return true;
    }
  }
  return false;
}
function buildMatchResult(match, error, iterations) {
  return {
    matched: match,
    iterations: iterations,
    error: error,
    getTrace: trace.getTrace,
    isType: trace.isType,
    isProperty: trace.isProperty,
    isKeyword: trace.isKeyword
  };
}
function matchSyntax(lexer, syntax, value, useCommon) {
  var tokens = prepareTokens(value, lexer.syntax);
  var result;
  if (valueHasVar(tokens)) {
    return buildMatchResult(null, new Error('Matching for a tree with var() is not supported'));
  }
  if (useCommon) {
    result = matchAsTree(tokens, lexer.valueCommonSyntax, lexer);
  }
  if (!useCommon || !result.match) {
    result = matchAsTree(tokens, syntax.match, lexer);
    if (!result.match) {
      return buildMatchResult(null, new SyntaxMatchError(result.reason, syntax.syntax, value, result), result.iterations);
    }
  }
  return buildMatchResult(result.match, null, result.iterations);
}
var Lexer = function (config, syntax, structure) {
  this.valueCommonSyntax = cssWideKeywords;
  this.syntax = syntax;
  this.generic = false;
  this.atrules = {};
  this.properties = {};
  this.types = {};
  this.structure = structure || getStructureFromConfig(config);
  if (config) {
    if (config.types) {
      for (var name in config.types) {
        this.addType_(name, config.types[name]);
      }
    }
    if (config.generic) {
      this.generic = true;
      for (var name in generic) {
        this.addType_(name, generic[name]);
      }
    }
    if (config.atrules) {
      for (var name in config.atrules) {
        this.addAtrule_(name, config.atrules[name]);
      }
    }
    if (config.properties) {
      for (var name in config.properties) {
        this.addProperty_(name, config.properties[name]);
      }
    }
  }
};
Lexer.prototype = {
  structure: {},
  checkStructure: function (ast) {
    function collectWarning(node, message) {
      warns.push({
        node: node,
        message: message
      });
    }
    var structure = this.structure;
    var warns = [];
    this.syntax.walk(ast, function (node) {
      if (structure.hasOwnProperty(node.type)) {
        structure[node.type].check(node, collectWarning);
      } else {
        collectWarning(node, 'Unknown node type `' + node.type + '`');
      }
    });
    return warns.length ? warns : false;
  },
  createDescriptor: function (syntax, type, name, parent = null) {
    var ref = {
      type: type,
      name: name
    };
    var descriptor = {
      type: type,
      name: name,
      parent: parent,
      syntax: null,
      match: null
    };
    if (typeof syntax === 'function') {
      descriptor.match = buildMatchGraph(syntax, ref);
    } else {
      if (typeof syntax === 'string') {
        // lazy parsing on first access
        Object.defineProperty(descriptor, 'syntax', {
          get: function () {
            Object.defineProperty(descriptor, 'syntax', {
              value: parse(syntax)
            });
            return descriptor.syntax;
          }
        });
      } else {
        descriptor.syntax = syntax;
      }

      // lazy graph build on first access
      Object.defineProperty(descriptor, 'match', {
        get: function () {
          Object.defineProperty(descriptor, 'match', {
            value: buildMatchGraph(descriptor.syntax, ref)
          });
          return descriptor.match;
        }
      });
    }
    return descriptor;
  },
  addAtrule_: function (name, syntax) {
    if (!syntax) {
      return;
    }
    this.atrules[name] = {
      type: 'Atrule',
      name: name,
      prelude: syntax.prelude ? this.createDescriptor(syntax.prelude, 'AtrulePrelude', name) : null,
      descriptors: syntax.descriptors ? Object.keys(syntax.descriptors).reduce((res, descName) => {
        res[descName] = this.createDescriptor(syntax.descriptors[descName], 'AtruleDescriptor', descName, name);
        return res;
      }, {}) : null
    };
  },
  addProperty_: function (name, syntax) {
    if (!syntax) {
      return;
    }
    this.properties[name] = this.createDescriptor(syntax, 'Property', name);
  },
  addType_: function (name, syntax) {
    if (!syntax) {
      return;
    }
    this.types[name] = this.createDescriptor(syntax, 'Type', name);
    if (syntax === generic['-ms-legacy-expression']) {
      this.valueCommonSyntax = cssWideKeywordsWithExpression;
    }
  },
  checkAtruleName: function (atruleName) {
    if (!this.getAtrule(atruleName)) {
      return new SyntaxReferenceError('Unknown at-rule', '@' + atruleName);
    }
  },
  checkAtrulePrelude: function (atruleName, prelude) {
    let error = this.checkAtruleName(atruleName);
    if (error) {
      return error;
    }
    var atrule = this.getAtrule(atruleName);
    if (!atrule.prelude && prelude) {
      return new SyntaxError('At-rule `@' + atruleName + '` should not contain a prelude');
    }
    if (atrule.prelude && !prelude) {
      return new SyntaxError('At-rule `@' + atruleName + '` should contain a prelude');
    }
  },
  checkAtruleDescriptorName: function (atruleName, descriptorName) {
    let error = this.checkAtruleName(atruleName);
    if (error) {
      return error;
    }
    var atrule = this.getAtrule(atruleName);
    var descriptor = names.keyword(descriptorName);
    if (!atrule.descriptors) {
      return new SyntaxError('At-rule `@' + atruleName + '` has no known descriptors');
    }
    if (!atrule.descriptors[descriptor.name] && !atrule.descriptors[descriptor.basename]) {
      return new SyntaxReferenceError('Unknown at-rule descriptor', descriptorName);
    }
  },
  checkPropertyName: function (propertyName) {
    var property = names.property(propertyName);

    // don't match syntax for a custom property
    if (property.custom) {
      return new Error('Lexer matching doesn\'t applicable for custom properties');
    }
    if (!this.getProperty(propertyName)) {
      return new SyntaxReferenceError('Unknown property', propertyName);
    }
  },
  matchAtrulePrelude: function (atruleName, prelude) {
    var error = this.checkAtrulePrelude(atruleName, prelude);
    if (error) {
      return buildMatchResult(null, error);
    }
    if (!prelude) {
      return buildMatchResult(null, null);
    }
    return matchSyntax(this, this.getAtrule(atruleName).prelude, prelude, false);
  },
  matchAtruleDescriptor: function (atruleName, descriptorName, value) {
    var error = this.checkAtruleDescriptorName(atruleName, descriptorName);
    if (error) {
      return buildMatchResult(null, error);
    }
    var atrule = this.getAtrule(atruleName);
    var descriptor = names.keyword(descriptorName);
    return matchSyntax(this, atrule.descriptors[descriptor.name] || atrule.descriptors[descriptor.basename], value, false);
  },
  matchDeclaration: function (node) {
    if (node.type !== 'Declaration') {
      return buildMatchResult(null, new Error('Not a Declaration node'));
    }
    return this.matchProperty(node.property, node.value);
  },
  matchProperty: function (propertyName, value) {
    var error = this.checkPropertyName(propertyName);
    if (error) {
      return buildMatchResult(null, error);
    }
    return matchSyntax(this, this.getProperty(propertyName), value, true);
  },
  matchType: function (typeName, value) {
    var typeSyntax = this.getType(typeName);
    if (!typeSyntax) {
      return buildMatchResult(null, new SyntaxReferenceError('Unknown type', typeName));
    }
    return matchSyntax(this, typeSyntax, value, false);
  },
  match: function (syntax, value) {
    if (typeof syntax !== 'string' && (!syntax || !syntax.type)) {
      return buildMatchResult(null, new SyntaxReferenceError('Bad syntax'));
    }
    if (typeof syntax === 'string' || !syntax.match) {
      syntax = this.createDescriptor(syntax, 'Type', 'anonymous');
    }
    return matchSyntax(this, syntax, value, false);
  },
  findValueFragments: function (propertyName, value, type, name) {
    return search.matchFragments(this, value, this.matchProperty(propertyName, value), type, name);
  },
  findDeclarationValueFragments: function (declaration, type, name) {
    return search.matchFragments(this, declaration.value, this.matchDeclaration(declaration), type, name);
  },
  findAllFragments: function (ast, type, name) {
    var result = [];
    this.syntax.walk(ast, {
      visit: 'Declaration',
      enter: function (declaration) {
        result.push.apply(result, this.findDeclarationValueFragments(declaration, type, name));
      }.bind(this)
    });
    return result;
  },
  getAtrule: function (atruleName, fallbackBasename = true) {
    var atrule = names.keyword(atruleName);
    var atruleEntry = atrule.vendor && fallbackBasename ? this.atrules[atrule.name] || this.atrules[atrule.basename] : this.atrules[atrule.name];
    return atruleEntry || null;
  },
  getAtrulePrelude: function (atruleName, fallbackBasename = true) {
    const atrule = this.getAtrule(atruleName, fallbackBasename);
    return atrule && atrule.prelude || null;
  },
  getAtruleDescriptor: function (atruleName, name) {
    return this.atrules.hasOwnProperty(atruleName) && this.atrules.declarators ? this.atrules[atruleName].declarators[name] || null : null;
  },
  getProperty: function (propertyName, fallbackBasename = true) {
    var property = names.property(propertyName);
    var propertyEntry = property.vendor && fallbackBasename ? this.properties[property.name] || this.properties[property.basename] : this.properties[property.name];
    return propertyEntry || null;
  },
  getType: function (name) {
    return this.types.hasOwnProperty(name) ? this.types[name] : null;
  },
  validate: function () {
    function validate(syntax, name, broken, descriptor) {
      if (broken.hasOwnProperty(name)) {
        return broken[name];
      }
      broken[name] = false;
      if (descriptor.syntax !== null) {
        walk(descriptor.syntax, function (node) {
          if (node.type !== 'Type' && node.type !== 'Property') {
            return;
          }
          var map = node.type === 'Type' ? syntax.types : syntax.properties;
          var brokenMap = node.type === 'Type' ? brokenTypes : brokenProperties;
          if (!map.hasOwnProperty(node.name) || validate(syntax, node.name, brokenMap, map[node.name])) {
            broken[name] = true;
          }
        }, this);
      }
    }
    var brokenTypes = {};
    var brokenProperties = {};
    for (var key in this.types) {
      validate(this, key, brokenTypes, this.types[key]);
    }
    for (var key in this.properties) {
      validate(this, key, brokenProperties, this.properties[key]);
    }
    brokenTypes = Object.keys(brokenTypes).filter(function (name) {
      return brokenTypes[name];
    });
    brokenProperties = Object.keys(brokenProperties).filter(function (name) {
      return brokenProperties[name];
    });
    if (brokenTypes.length || brokenProperties.length) {
      return {
        types: brokenTypes,
        properties: brokenProperties
      };
    }
    return null;
  },
  dump: function (syntaxAsAst, pretty) {
    return {
      generic: this.generic,
      types: dumpMapSyntax(this.types, !pretty, syntaxAsAst),
      properties: dumpMapSyntax(this.properties, !pretty, syntaxAsAst),
      atrules: dumpAtruleMapSyntax(this.atrules, !pretty, syntaxAsAst)
    };
  },
  toString: function () {
    return JSON.stringify(this.dump());
  }
};
module.exports = Lexer;