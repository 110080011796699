// '/' | '*' | ',' | ':' | '+' | '-'
module.exports = {
  name: 'Operator',
  structure: {
    value: String
  },
  parse: function () {
    var start = this.scanner.tokenStart;
    this.scanner.next();
    return {
      type: 'Operator',
      loc: this.getLocation(start, this.scanner.tokenStart),
      value: this.scanner.substrToCursor(start)
    };
  },
  generate: function (node) {
    this.chunk(node.value);
  }
};