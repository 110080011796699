var TYPE = require('../../tokenizer').TYPE;
var IDENT = TYPE.Ident;
var PLUSSIGN = 0x002B; // U+002B PLUS SIGN (+)
var SOLIDUS = 0x002F; // U+002F SOLIDUS (/)
var GREATERTHANSIGN = 0x003E; // U+003E GREATER-THAN SIGN (>)
var TILDE = 0x007E; // U+007E TILDE (~)

// + | > | ~ | /deep/
module.exports = {
  name: 'Combinator',
  structure: {
    name: String
  },
  parse: function () {
    var start = this.scanner.tokenStart;
    var code = this.scanner.source.charCodeAt(this.scanner.tokenStart);
    switch (code) {
      case GREATERTHANSIGN:
      case PLUSSIGN:
      case TILDE:
        this.scanner.next();
        break;
      case SOLIDUS:
        this.scanner.next();
        if (this.scanner.tokenType !== IDENT || this.scanner.lookupValue(0, 'deep') === false) {
          this.error('Identifier `deep` is expected');
        }
        this.scanner.next();
        if (!this.scanner.isDelim(SOLIDUS)) {
          this.error('Solidus is expected');
        }
        this.scanner.next();
        break;
      default:
        this.error('Combinator is expected');
    }
    return {
      type: 'Combinator',
      loc: this.getLocation(start, this.scanner.tokenStart),
      name: this.scanner.substrToCursor(start)
    };
  },
  generate: function (node) {
    this.chunk(node.name);
  }
};