"use strict";

module.exports = function () {
  var assign = Object.assign,
    obj;
  if (typeof assign !== "function") return false;
  obj = {
    foo: "raz"
  };
  assign(obj, {
    bar: "dwa"
  }, {
    trzy: "trzy"
  });
  return obj.foo + obj.bar + obj.trzy === "razdwatrzy";
};