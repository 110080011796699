"use strict";

var sign = require("../math/sign"),
  abs = Math.abs,
  floor = Math.floor;
module.exports = function (value) {
  if (isNaN(value)) return 0;
  value = Number(value);
  if (value === 0 || !isFinite(value)) return value;
  return sign(value) * floor(abs(value));
};