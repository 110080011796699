"use strict";

var keys = require("../keys"),
  value = require("../valid-value"),
  max = Math.max;
module.exports = function (dest, src /*, …srcn*/) {
  var error,
    i,
    length = max(arguments.length, 2),
    assign;
  dest = Object(value(dest));
  assign = function (key) {
    try {
      dest[key] = src[key];
    } catch (e) {
      if (!error) error = e;
    }
  };
  for (i = 1; i < length; ++i) {
    src = arguments[i];
    keys(src).forEach(assign);
  }
  if (error !== undefined) throw error;
  return dest;
};