import { Component, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { ApiService } from '../core/api.service';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { LetDirective } from '@ngrx/component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';
import { Title } from '@angular/platform-browser';
import { PentestScan, Run } from '@codenteam/portal/graphql';
import { ReportService } from './report.service';
import { OverallRunStatus } from '@codenteam/core/utils/dtos/overall-run-status.dto';
import { AnalyticsService } from '../core/analytics/analytics.service.abstract';

@Component({
  selector: 'codenteam-report',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    MatDividerModule,
    LetDirective,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTooltip,
  ],
  templateUrl: './create-report.component.html',
  styleUrl: './create-report.component.scss',
})
export class CreateReportComponent implements OnInit {
  runs$ = this.apiService.getAllRunsWithShared(null, true);
  scans$ = this.apiService.scansList();
  form = new FormGroup(
    {
      runsControl: new FormControl([]),
      scansControl: new FormControl([]),
    },
    { validators: requireOneControl }
  );
  selectedRunUUID: string;
  selectedPentestUUID: string;
  runOverallStatus = OverallRunStatus;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private reportService: ReportService,
    private mixpanelService: AnalyticsService
  ) {
    this.setTitle();
  }
  ngOnInit(): void {
    this.setSelectedRun();
    this.setSelectedPentest();
  }
  setSelectedRun() {
    this.reportService.runUUID$.subscribe((runUUID) => {
      if (runUUID) {
        this.selectedRunUUID = runUUID;
        this.runs$.subscribe((runs) => {
          const run = runs.find((run) => run.uuid === this.selectedRunUUID);
          if (run) {
            this.form
              .get('runsControl')
              ?.setValue([run.uuid], { emitEvent: false });
          }
        });
      }
    });
  }

  setSelectedPentest() {
    this.reportService.pentestUUID$.subscribe((pentestUUID: string) => {
      if (pentestUUID) {
        this.selectedPentestUUID = pentestUUID;
        this.scans$.subscribe((scans) => {
          const scan = scans.find(
            (scan) => scan.uuid === this.selectedPentestUUID
          );
          if (scan) {
            this.form
              .get('scansControl')
              ?.setValue([scan.uuid], { emitEvent: false });
          }
        });
      }
    });
  }

  setTitle() {
    this.titleService.setTitle(`Codenteam - Report - Creation`);
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    this.mixpanelService.trackEvent('genereate-report-from-reports-center', {
      runIds: this.form.controls.runsControl.value,
      scanIds: this.form.controls.scansControl.value,
    });
    const queryParams: any = {};
    queryParams.scansArray = JSON.stringify(
      this.form.controls.scansControl.value
    );
    queryParams.runsArray = JSON.stringify(
      this.form.controls.runsControl.value
    );
    queryParams.print = 'true';
    const navigationExtras: NavigationExtras = {
      queryParams,
    };

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['report/report-review'], navigationExtras)
    );
    window.open(url, '_blank');
  }
  checkScans(scans: PentestScan[]) {
    const scansDone = scans?.filter((scan) => scan.status === 'Done').length;
    return scansDone === 0 ? true : false;
  }

  filterRuns(runs: Run[]): Run[] {
    return runs.filter(
      (run) =>
        run.overallRunStatus === this.runOverallStatus.Ready ||
        run.overallRunStatus === this.runOverallStatus.UnderstandingDependencies
    );
  }
}
const requireOneControl: ValidatorFn = (
  form: AbstractControl
): ValidationErrors | null => {
  if (
    form.get('runsControl').value.length === 0 &&
    form.get('scansControl').value.length === 0
  ) {
    return { required: 'at least one run or scan is required' };
  }
  return null;
};
