import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsService } from '../../core/notifications.service';
import {
  interval,
  map,
  merge,
  Observable,
  share,
  startWith,
  Subject,
  switchMap,
} from 'rxjs';
import { NotificationType, StateOptions } from '@codenteam/portal/graphql';
import { NotificationCoreService } from './notification-core.service';
import { LetDirective } from '@ngrx/component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { TimeAgoModule } from '@codenteam/ui/time-ago/time-ago.module';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'codenteam-notifications',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    MatButtonModule,
    MatMenuModule,
    MatBadgeModule,
    MatIconModule,
    TimeAgoModule,
    MatDividerModule,
  ],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
})
export class NotificationsComponent {
  clearNotifications$ = new Subject<void>();
  markNotificationAsRead$ = new Subject<{
    notifications: NotificationType[];
    index: number;
    unreadNotificationsCount: number;
  }>();
  isMenuOpen: boolean = false;

  constructor(
    private notificationService: NotificationsService,
    public notificationCoreService: NotificationCoreService
  ) {}

  notificationsPolling$ = interval(10000).pipe(
    startWith(0),
    switchMap(() => this.notificationService.getAllUserNotifications()),
    share()
  );

  clearedNotifications$ = this.clearNotifications$.pipe(
    switchMap(() =>
      this.notificationService.clearAllUserNotifications().pipe(
        map(() => ({
          notifications: [], // Empty array for cleared notifications
          unreadNotificationsCount: 0, // Reset unread count to 0
        }))
      )
    ),
    share()
  );

  notificationsData$ = merge(
    this.notificationsPolling$,
    this.clearedNotifications$,
    this.markNotificationAsRead$.pipe(
      switchMap(({ notifications, index, unreadNotificationsCount }) =>
        this.notificationService
          .markNotificationAsRead(notifications[index].uuid)
          .pipe(
            map((response) => {
              if (response) {
                notifications[index].readAt = new Date();
                unreadNotificationsCount -= 1;
                return { notifications, unreadNotificationsCount };
              } else {
                return { notifications, unreadNotificationsCount };
              }
            })
          )
      ),
      share()
    )
  ) as Observable<{
    notifications: NotificationType[];
    unreadNotificationsCount: number;
  }>;

  clearNotifications() {
    this.clearNotifications$.next();
  }
  notificationNavigate(
    notifications: NotificationType[],
    index: number,
    unreadNotificationsCount: number
  ) {
    const notification = notifications[index];
    if (
      notification.data.extra.state !== StateOptions.Failed &&
      notification.data.extra.state != StateOptions.Paused
    ) {
      this.notificationCoreService.navigate(notification);
    }

    if (!notification.readAt) {
      this.markNotificationAsRead$.next({
        notifications,
        index,
        unreadNotificationsCount,
      });
    }
  }
}
