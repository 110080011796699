var List = require('../common/List');
function getFirstMatchNode(matchNode) {
  if ('node' in matchNode) {
    return matchNode.node;
  }
  return getFirstMatchNode(matchNode.match[0]);
}
function getLastMatchNode(matchNode) {
  if ('node' in matchNode) {
    return matchNode.node;
  }
  return getLastMatchNode(matchNode.match[matchNode.match.length - 1]);
}
function matchFragments(lexer, ast, match, type, name) {
  function findFragments(matchNode) {
    if (matchNode.syntax !== null && matchNode.syntax.type === type && matchNode.syntax.name === name) {
      var start = getFirstMatchNode(matchNode);
      var end = getLastMatchNode(matchNode);
      lexer.syntax.walk(ast, function (node, item, list) {
        if (node === start) {
          var nodes = new List();
          do {
            nodes.appendData(item.data);
            if (item.data === end) {
              break;
            }
            item = item.next;
          } while (item !== null);
          fragments.push({
            parent: list,
            nodes: nodes
          });
        }
      });
    }
    if (Array.isArray(matchNode.match)) {
      matchNode.match.forEach(findFragments);
    }
  }
  var fragments = [];
  if (match.matched !== null) {
    findFragments(match.matched);
  }
  return fragments;
}
module.exports = {
  matchFragments: matchFragments
};