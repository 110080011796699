module.exports = {
  name: 'Selector',
  structure: {
    children: [['TypeSelector', 'IdSelector', 'ClassSelector', 'AttributeSelector', 'PseudoClassSelector', 'PseudoElementSelector', 'Combinator', 'WhiteSpace']]
  },
  parse: function () {
    var children = this.readSequence(this.scope.Selector);

    // nothing were consumed
    if (this.getFirstListNode(children) === null) {
      this.error('Selector is expected');
    }
    return {
      type: 'Selector',
      loc: this.getLocationFromList(children),
      children: children
    };
  },
  generate: function (node) {
    this.children(node);
  }
};