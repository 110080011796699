// https://www.w3.org/TR/css3-page/#page-size-prop

export default {
  "A0": {
    width: {
      value: 841,
      unit: "mm"
    },
    height: {
      value: 1189,
      unit: "mm"
    }
  },
  "A1": {
    width: {
      value: 594,
      unit: "mm"
    },
    height: {
      value: 841,
      unit: "mm"
    }
  },
  "A2": {
    width: {
      value: 420,
      unit: "mm"
    },
    height: {
      value: 594,
      unit: "mm"
    }
  },
  "A3": {
    width: {
      value: 297,
      unit: "mm"
    },
    height: {
      value: 420,
      unit: "mm"
    }
  },
  "A4": {
    width: {
      value: 210,
      unit: "mm"
    },
    height: {
      value: 297,
      unit: "mm"
    }
  },
  "A5": {
    width: {
      value: 148,
      unit: "mm"
    },
    height: {
      value: 210,
      unit: "mm"
    }
  },
  "A6": {
    width: {
      value: 105,
      unit: "mm"
    },
    height: {
      value: 148,
      unit: "mm"
    }
  },
  "A7": {
    width: {
      value: 74,
      unit: "mm"
    },
    height: {
      value: 105,
      unit: "mm"
    }
  },
  "A8": {
    width: {
      value: 52,
      unit: "mm"
    },
    height: {
      value: 74,
      unit: "mm"
    }
  },
  "A9": {
    width: {
      value: 37,
      unit: "mm"
    },
    height: {
      value: 52,
      unit: "mm"
    }
  },
  "A10": {
    width: {
      value: 26,
      unit: "mm"
    },
    height: {
      value: 37,
      unit: "mm"
    }
  },
  "B4": {
    width: {
      value: 250,
      unit: "mm"
    },
    height: {
      value: 353,
      unit: "mm"
    }
  },
  "B5": {
    width: {
      value: 176,
      unit: "mm"
    },
    height: {
      value: 250,
      unit: "mm"
    }
  },
  "letter": {
    width: {
      value: 8.5,
      unit: "in"
    },
    height: {
      value: 11,
      unit: "in"
    }
  },
  "legal": {
    width: {
      value: 8.5,
      unit: "in"
    },
    height: {
      value: 14,
      unit: "in"
    }
  },
  "ledger": {
    width: {
      value: 11,
      unit: "in"
    },
    height: {
      value: 17,
      unit: "in"
    }
  }
};