"use strict";

var d = require("d");
var create = Object.create,
  defineProperty = Object.defineProperty,
  objPrototype = Object.prototype;
var created = create(null);
module.exports = function (desc) {
  var postfix = 0,
    name,
    ie11BugWorkaround;
  while (created[desc + (postfix || "")]) ++postfix;
  desc += postfix || "";
  created[desc] = true;
  name = "@@" + desc;
  defineProperty(objPrototype, name, d.gs(null, function (value) {
    // For IE11 issue see:
    // https://connect.microsoft.com/IE/feedbackdetail/view/1928508/
    //    ie11-broken-getters-on-dom-objects
    // https://github.com/medikoo/es6-symbol/issues/12
    if (ie11BugWorkaround) return;
    ie11BugWorkaround = true;
    defineProperty(this, name, d(value));
    ie11BugWorkaround = false;
  }));
  return name;
};