var TYPE = require('../../tokenizer').TYPE;
var STRING = TYPE.String;
var IDENT = TYPE.Ident;
var URL = TYPE.Url;
var FUNCTION = TYPE.Function;
var LEFTPARENTHESIS = TYPE.LeftParenthesis;
module.exports = {
  parse: {
    prelude: function () {
      var children = this.createList();
      this.scanner.skipSC();
      switch (this.scanner.tokenType) {
        case STRING:
          children.push(this.String());
          break;
        case URL:
        case FUNCTION:
          children.push(this.Url());
          break;
        default:
          this.error('String or url() is expected');
      }
      if (this.lookupNonWSType(0) === IDENT || this.lookupNonWSType(0) === LEFTPARENTHESIS) {
        children.push(this.WhiteSpace());
        children.push(this.MediaQueryList());
      }
      return children;
    },
    block: null
  }
};